import './style.scss'

import React from 'react'
import { connect } from 'react-redux'

const Component = (props) => {
	return (
		<div className="instructions">
			<p>In dit spel lopen alle groepen rond door het dorp. Om actief (levend) te worden, moet je eerst naar het aan jou toegewezen waypoint gaan. Zodra je daar bent, krijg je een doelwit toegewezen. Je pakt (vermoord) je doelwit door te zorgen dat jouw begeleider voldoende dichtbij hun begeleider is. Zodra dit het geval is krijg je een punt. Let wel op: begeleiders doen over het algemeen niet aan rennen. Dit kan een voordeel zijn, maar ook een nadeel.</p>
			<p>Om te helpen bij het vinden van je doelwit, krijg je om de zoveel tijd hun locatie door. Dit begint langzaam (elke {Math.round(props.gameSettings.initialDelay/60)} minuten) maar gaat steeds sneller. Uiteindelijk zul je dus zeker je doelwit vinden.</p>
			<p>Heb je iemand gepakt? Dan mag je gelijk door: je krijgt het oude doelwit van het gepakte team. Ben je vermoord? Dan moet je eerst weer naar een waypoint om levend te worden.</p>
			<p>Het kan soms voorkomen dat er even geen doelwit voor je beschikbaar is. In dit geval krijg je kort de tijd om het geheime begeleidersteam op te sporen. Lukt je dit? Dan krijg je een half punt en iets lekkers. Maar let op: als er een nieuw doelwit beschikbaar komt voordat je dit team gevonden hebt, dan is je kans voorbij! Je mag dan "gewoon" op dit nieuwe doelwit jagen.</p>
		</div>
	)
}

const stateMap = (state) => ({
	gameSettings: state.gameSettings,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)