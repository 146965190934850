import { NOT_FOUND } from 'redux-first-router'

import Home from './Home'
import Settings from './Settings'
import Target from './Target'
import Instructions from './Instructions'
import Notifications from './Notifications'
import Admin from './Admin'
import Overview from './Overview'
import Ranking from './Ranking'
import TeamLead from './TeamLead'
import NotFound from './NotFound'

import { isLoggedIn } from '../../redux/team/functions'

const pages = {
	HOME: {
		component: Home,
		title: 'Speurtocht',
		skipPrefix: true, // Do not use a prefix in the <title>.
		path: '/(index.html)?', // Make both the base path '/' and '/index.html' point to the Home page. The latter is called when a PWA starts up on a smartphone as local app.
	},
	SETTINGS: {
		component: Settings,
		title: 'Teaminstellingen',
		path: '/teaminstellingen',
		allowed: (team) => isLoggedIn(team) && team.teamLead,
	},
	TARGET: {
		component: Target,
		title: 'Doelwit',
		path: '/doelwit',
		allowed: isLoggedIn,
	},
	INSTRUCTIONS: {
		component: Instructions,
		title: 'Instructies',
		path: '/instructies',
	},
	NOTIFICATIONS: {
		component: Notifications,
		title: 'Notificaties',
		path: '/notificaties',
		allowed: isLoggedIn,
	},
	RANKING: {
		component: Ranking,
		title: 'Ranglijst',
		path: '/ranglijst',
	},
	ADMIN: {
		component: Admin,
		title: 'Beheer',
		path: '/beheer',
	},
	OVERVIEW: {
		component: Overview,
		title: 'Overzicht',
		path: '/overzicht',
	},
	TEAMLEAD: {
		component: TeamLead,
		title: 'Begeleiderssectie',
		path: '/begeleider',
	},
	[NOT_FOUND]: {
		component: NotFound,
		title: 'Oops ...',
		path: '/notfound',
	},
}

for (let name in pages) {
	pages[name].name = name
}
export default pages

export function getTitle(page, payload, user) {
	if (typeof(page.title) === 'function')
		return page.title(payload, user)
	return page.title
}

// Set up a routes object that can be used by redux-first-router.
const routes = {}
for (let name in pages) {
	if (pages[name].path)
		routes[name] = pages[name].path
}
export { routes }