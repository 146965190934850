import './style.scss'

import React, { useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import md5 from 'md5'

import firebase from '../../../config/firebase'
import teamActions from '../../../redux/team/actions'
import { teamNameToIndex, createNewTeam } from '../../../redux/team/functions'

const handleSubmit = (event, data, newTeam, teamLead, logIn) => {
	event.preventDefault() // Don't submit form and refresh page.

	// Check which action we need to do.
	const teamName = teamNameToIndex(data.name)
	if (newTeam) {
		// Check for basics.
		if (data.name.trim().length < 2)
			return window.alert('De naam is te kort.')
		if (data.password.trim().length < 4)
			return window.alert('Het wachtwoord is te kort.')

		// Check for uniqueness.
		const ref = firebase.database().ref(`teams/private/${teamName}`)
		ref.once('value', (snapshot) => {
			// Check existence.
			if (snapshot.val())
				return window.alert('Team van die begeleider bestaat al.')

			// Insert team.
			createNewTeam(data.name, data.password).then(() => logIn(teamName, teamLead))
		}, () => window.alert('Er is een onbekende fout opgetreden.'))
	} else {
		// Check if team name is valid.
		if (data.name.trim().length < 2)
			return window.alert('Teamnaam is ongeldig.')

		// Get team data.
		firebase.database().ref(`teams/private/${teamName}`).once('value', (snapshot) => {
			// Check existence.
			const privateTeamData = snapshot.val()
			if (!privateTeamData)
				return window.alert('Er is nog geen team met die begeleidernaam.')

			// Check password.
			if (md5(data.password) !== privateTeamData.password)
				return window.alert('Incorrect wachtwoord.')

			// All good. Log in.
			logIn(teamName, teamLead)
		}, () => window.alert('Er is een onbekende fout opgetreden.'))
	}
}

const Component = ({ teamLead, logIn }) => {
	const [newTeam, setNewTeam] = useState(false)
	const [data, setData] = useState({ name: '', password: '' })

	const handleDataChange = (event) => setData({ ...data, [event.target.id]: event.target.value })
	const handleFormSubmit = (event) => handleSubmit(event, data, newTeam, teamLead, logIn)

	return (
		<div className="logIn">
			{teamLead ? <>
				<p>Je kan hier inloggen als begeleider. Alleen van begeleiders wordt daadwerkelijk de positie bijgehouden. Deelnemers kunnen ook inloggen, maar zij krijgen alleen alle teaminformatie zonder dat ze zelf iets kunnen doen.</p>
				<p>Onthoud: van elk team moet altijd exact één begeleider ingelogd zijn. Meerdere begeleiders geeft chaos door dubbele positie-updates en nul begeleiders geeft chaos door ontbrekende positie-updates.</p>
			</> : <></>}
			<form onSubmit={handleFormSubmit} className="logInForm">
				{teamLead ? <div className="btnRow">
					<div className={classnames('btn', newTeam ? 'selectable' : 'selected')} onClick={() => setNewTeam(false)}>Log in op bestaand team</div>
					<div className={classnames('btn', newTeam ? 'selected' : 'selectable')} onClick={() => setNewTeam(true)}>Maak nieuw team</div>
				</div> : <></>}
				<div className="inputRow">
					<label htmlFor="name">Begeleider voornaam</label>
					<input id="name" type="text" onChange={handleDataChange} value={data.name} />
				</div>
				<div className="inputRow">
					<label htmlFor="name">Team wachtwoord</label>
					<input id="password" type="password" onChange={handleDataChange} value={data.password} />
				</div>
				<div className="btnRow">
					<input type="submit" className="btn submit" onClick={handleFormSubmit} value="Begin met het spel!" />
				</div>
			</form>
		</div>
	)
}

const stateMap = (state) => ({})
const actionMap = (dispatch) => ({
	logIn: (team, teamLead) => dispatch(teamActions.logIn(team, teamLead)),
})
export default connect(stateMap, actionMap)(Component)