import React from 'react'
import Wrapper from './Wrapper.js'

export default (props) => (
	<Wrapper className={props.className}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
			<g>
				<polygon fill="currentColor" points="0,0 0,100 100,0" />
				<polygon fill="currentColor" points="100,0 200,100 200,0" />
				<polygon fill="currentColor" points="0,200 100,200 0,100" />
				<polygon fill="currentColor" points="200,200 200,100 100,200" />
			</g>
			<g>
				<polygon fill="currentColor" points="50,50 50,100 100,50" />
				<polygon fill="currentColor" points="100,50 150,100 150,50" />
				<polygon fill="currentColor" points="50,150 100,150 50,100" />
				<polygon fill="currentColor" points="150,150 150,100 100,150" />
			</g>
			<g>
				<polygon fill="currentColor" points="75,75 75,100 100,75" />
				<polygon fill="currentColor" points="100,75 125,100 125,75" />
				<polygon fill="currentColor" points="75,125 100,125 75,100" />
				<polygon fill="currentColor" points="125,125 125,100 100,125" />
			</g>
		</svg>
	</Wrapper>
)
