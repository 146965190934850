import './style.scss'

import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import loadGoogleMapsAPI from 'load-google-maps-api'

import config from '../../../config/config.json'
import { defaultLocation, defaultZoom, colors, colorToHex } from '../../../settings'

let map // This will be a pointer to the Google maps object.
let mapObj // This will be a pointer to the map div.
let teamData
let waypointData
let desiredOrgaTeamPositionData

// Load the Google maps interface.
let GM
if (window.google && window.google.maps) {
	GM = window.google.maps
} else {
	loadGoogleMapsAPI({ key: config.mapAPIKey })
		.then((result) => { GM = result })
		.catch((error) => { console.error('An error occurred while loading Google Maps', error) })
}

// initializeMap sets up a Google Map object in the designated div. 
const initializeMap = () => {
	// Check if we can actually initialize stuff.
	if (!mapObj)
		return false

	// Set up the map.
	const options = {
		zoom: defaultZoom,
		center: defaultLocation,
		clickableIcons: false, // This is to prevent clickable icons on the map. They disrupt our own events, which is annoying.
		styles: [{ // No places of interest (businesses and such).
			featureType: 'poi',
			stylers: [{ visibility: 'off' }],
		}],
	}
	map = new GM.Map(mapObj, options)

	// Set up event listeners.
	map.addListener('zoom_changed', updateMap) // When the zoom changes, we need to redraw all markers to make them smaller/larger.

	// Note that initialization was successful.
	return true
}

let waypointCircles
let teamCircles
let desiredOrgaTeamPositionCircle
const updateMap = () => {
	// Check if we have all data.
	if (!mapObj || !GM || !teamData || !waypointData)
		return
	// If we haven't initialized the map yet, do so right away.
	if (!map && !initializeMap())
		return

	// Draw the waypoint circles.
	if (waypointCircles)
		waypointCircles.forEach(circle => circle.setMap(null))
	if (waypointData) {
		waypointCircles = []
		waypointData.forEach((position, i) => {
			const pos = { lat: position.latitude, lng: position.longitude }
			waypointCircles.push(new GM.Circle({
				strokeColor: '#000000',
				strokeOpacity: 1,
				strokeWeight: 0,
				fillColor: '#000000',
				fillOpacity: 1,
				map: map,
				center: pos,
				radius: 0.6 * Math.pow(2, 20 - map.zoom),
			}))
		})
	}

	// Draw the team circles.
	if (teamCircles)
		teamCircles.forEach(circle => circle.setMap(null))
	if (teamData) {
		teamCircles = []
		teamData.forEach((team) => {
			if (!team.position)
				return
			const pos = { lat: team.position.latitude, lng: team.position.longitude }
			const color = colorToHex(colors[team.index % colors.length])
			teamCircles.push(new GM.Circle({
				strokeColor: color,
				strokeOpacity: 1,
				strokeWeight: 0,
				fillColor: color,
				fillOpacity: 1,
				map: map,
				center: pos,
				radius: 0.6 * Math.pow(2, 20 - map.zoom),
			}))
		})
	}
	// Draw the team circles.
	if (desiredOrgaTeamPositionCircle)
		desiredOrgaTeamPositionCircle.setMap(null)
	if (desiredOrgaTeamPositionData) {
		const pos = { lat: desiredOrgaTeamPositionData.latitude, lng: desiredOrgaTeamPositionData.longitude }
		desiredOrgaTeamPositionCircle = new GM.Circle({
			strokeColor: '#777777',
			strokeOpacity: 1,
			strokeWeight: 0,
			fillColor: '#777777',
			fillOpacity: 1,
			map: map,
			center: pos,
			radius: 0.6 * Math.pow(2, 20 - map.zoom),
		})
	}
}

// The target component.
const Component = ({ gameSettings, teams }) => {
	const { waypoints, desiredOrgaTeamPosition } = gameSettings
	const mapDiv = useRef(null)

	// Store the right mapDiv.
	useEffect(() => {
		mapObj = mapDiv.current
	})

	// Upon dismounting, remove the map reference. We'll get a new map.
	useEffect(() => {
		return () => {
			map = undefined
		}
	}, [])

	// When something changes, update the map.
	useEffect(() => {
		teamData = teams
		waypointData = waypoints
		desiredOrgaTeamPositionData = desiredOrgaTeamPosition
		updateMap()
	}, [teams, waypoints, desiredOrgaTeamPosition])

	// Show the target map.
	return (
		<div className="overviewMap">
			{GM ? (
				<div className="mapContainer">
					<div key="googleMap" id="googleMap" ref={mapDiv} />
				</div>
			) : <div className="mapLoader">Loading map ... </div>}
		</div>
	)
}

const stateMap = (state) => ({
	gameSettings: state.gameSettings,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)