import './style.scss'

import React from 'react'
import Link from 'redux-first-router-link'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import pages, { getTitle } from '../../pages'
import MenuItem from '../../components/MenuItem'
import VVW from '../../icons/VVW.js'
import Map from '../../icons/Map.js'
import Notifications from '../../icons/Notifications.js'
import Cog from '../../icons/Cog.js'
import Info from '../../icons/Info.js'
import Ranking from '../../icons/Ranking.js'
import Cross from '../../icons/Cross.js'

import teamActions from '../../../redux/team/actions'
import { isLoggedIn } from '../../../redux/team/functions'

const Component = (props) => {
	const page = pages[props.locationType] || pages.NOTFOUND
	return (
		<header className="header">
			<div className="contents">
				<div className="title">
					<Link to={{ type: 'HOME' }} className="logoLink">
						<VVW className="logo" />
					</Link>
					<h1>{getTitle(page, props.payload, props.user)}</h1>
					<Helmet>
						<title>{page.skipPrefix ? '' : 'Speurtocht - '}{getTitle(page, props.payload, props.user)}</title>
					</Helmet>
				</div>
				{isLoggedIn(props.team) ? (
					<nav className="menu">
						<MenuItem link="TARGET" icon={Map} label="Doelwit" />
						<MenuItem link="NOTIFICATIONS" icon={Notifications} label="Notificaties" />
						{props.team.teamLead ? <MenuItem link="SETTINGS" icon={Cog} label="Instellingen" /> : <></>}
						<MenuItem link="INSTRUCTIONS" icon={Info} label="Speluitleg" />
						<MenuItem link="RANKING" icon={Ranking} label="Ranglijst" />
						<MenuItem link={props.team.teamLead ? 'TEAMLEAD' : 'HOME'} icon={Cross} label="Log uit" active={false} onClick={props.logOut} />
					</nav>
				) : (
						<nav className="menu">
							<MenuItem link="INSTRUCTIONS" icon={Info} label="Speluitleg" />
							<MenuItem link="RANKING" icon={Ranking} label="Ranglijst" />
						</nav>
					)}
			</div>
		</header>
	)
}

const stateMap = (state) => ({
	locationType: state.location.type,
	payload: state.location.payload,
	team: state.team,
})
const actionMap = (dispatch) => ({
	logOut: () => dispatch(teamActions.logOut())
})
export default connect(stateMap, actionMap)(Component)