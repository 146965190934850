import './style.scss'

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import firebase from '../../../config/firebase.js'
import { addNotification, teamNameToIndex, teamReachedTarget } from '../../../redux/team/functions'
import { getDefaultState } from '../../../redux/gameSettings/reducer'

const Component = ({ gameSettings }) => {
	const [localSettings, setLocalSettings] = useState(getDefaultState())
	const [targetCompletionTeam, setTargetCompletionTeam] = useState('none')
	const [notificationTeam, setNotificationTeam] = useState('none')
	const [notification, setNotification] = useState('')
	const [allTeamNames, setAllTeamNames] = useState([])

	useEffect(() => { setLocalSettings(gameSettings) }, [gameSettings])
	useEffect(() => {
		// Start tracking the game settings.
		const ref = firebase.database().ref('teams/public')
		const listener = ref.on('value', (snapshot) => {
			const teamsPublicData = snapshot.val()
			const teamNames = Object.keys(teamsPublicData).map(teamName => teamsPublicData[teamName].name)
			setAllTeamNames(teamNames)
		})
		return () => { ref.off('value', listener) } // Close the connection.
	}, [])

	const applySetting = (parameter, value) => setLocalSettings({ ...localSettings, [parameter]: value })
	const confirmSettings = (event) => {
		event.preventDefault()

		// Check input.
		if (!(localSettings.initialDelay >= 5 && localSettings.initialDelay <= 3600)) {
			window.alert("Invalid initial delay")
			return
		}
		if (!(localSettings.timeFactor >= 0.2 && localSettings.timeFactor < 1)) {
			window.alert("Invalid time factor")
			return
		}
		if (!(localSettings.appointNewGroupToBackLimit >= 2 && localSettings.appointNewGroupToBackLimit < 10)) {
			window.alert("Invalid stack placement limit")
			return
		}
		if (!(localSettings.appointNewGroupToBack >= 0 && localSettings.appointNewGroupToBack < localSettings.appointNewGroupToBackLimit)) {
			window.alert("Invalid stack placement index")
			return
		}

		// All valid. Send to database.
		firebase.database().ref(`gameSettings`).update(localSettings)
		window.alert("Settings updated!")
	}

	const confirmNotification = (event) => {
		event.preventDefault()

		// Check input.
		if (notificationTeam === 'none')
			return window.alert('Fout: geen team geselecteerd.')
		if (notification.length < 4)
			return window.alert('Fout: notificatiebericht is te kort.')

		// Find teams to send it to.
		let receivers
		if (notificationTeam === 'all')
			receivers = allTeamNames.map(name => teamNameToIndex(name))
		else if (notificationTeam === 'allBut')
			receivers = allTeamNames.map(name => teamNameToIndex(name)).filter(name => name !== gameSettings.orgaTeam)
		else if (notificationTeam === 'orgaTeam')
			receivers = [gameSettings.orgaTeam]
		else
			receivers = [notificationTeam]
		
		// Send notifications.
		receivers.forEach(teamName => addNotification(teamName, notification))
		setNotification('')
		setNotificationTeam('none')
		window.alert(`Notificatie is succesvol verstuurd naar ${receivers.length} team${receivers.length === 1 ? '' : 's'}.`)
	}

	const confirmTargetCompletion = (event) => {
		event.preventDefault()

		// Check the team that got submitted.
		if (targetCompletionTeam === 'none')
			return

		// Reset the select field.
		setTargetCompletionTeam('none')

		// Check the game settings. Is this the orgaTeam?
		if (gameSettings.orgaTeam === targetCompletionTeam)
			return window.alert('Het begeleidersteam heeft geen doel om te halen.')
		
		// Process the change.
		teamReachedTarget(targetCompletionTeam).then(() => window.alert('De wijziging is verwerkt!'))
	}

	return (
		<div className="admin">
			<h2>Instellingen</h2>
			<form onSubmit={confirmSettings} className="adminForm">
				<div className="inputRow">
					<label htmlFor="status">Spel status</label>
					<select id="status" name="status" value={localSettings.status} onChange={(event) => applySetting('status', event.target.value)}>
						<option value="0">Nog niet begonnen</option>
						<option value="1">Actief</option>
						<option value="2">Afgelopen</option>
					</select>
				</div>
				<div className="inputRow">
					<label htmlFor="initialDelay">Seconden voor eerste update</label>
					<input type="text" value={localSettings.initialDelay} onChange={(event) => applySetting('initialDelay', event.target.value)} />
				</div>
				<div className="inputRow">
					<label htmlFor="timeFactor">Tijdsfactor</label>
					<input type="text" value={localSettings.timeFactor} onChange={(event) => applySetting('timeFactor', event.target.value)} />
				</div>
				<p>Het duurt dus {Math.round(localSettings.initialDelay / 60 * 10) / 10}/(1-{localSettings.timeFactor}) = {Math.round(localSettings.initialDelay / 60 / (1 - localSettings.timeFactor) * 10) / 10} minuten voordat een groep continue updates krijgt over hun doelwit.</p>
				<div className="inputRow">
					<label htmlFor="timeFactor">Stack placement index</label>
					<input type="text" value={localSettings.appointNewGroupToBack} onChange={(event) => applySetting('appointNewGroupToBack', event.target.value)} />
				</div>
				<div className="inputRow">
					<label htmlFor="timeFactor">Stack placement index maximum</label>
					<input type="text" value={localSettings.appointNewGroupToBackLimit} onChange={(event) => applySetting('appointNewGroupToBackLimit', event.target.value)} />
				</div>
				<input type="submit" className="btn submit" onClick={confirmSettings} value="Bevestig" />
			</form>
			<h2>Notificaties versturen</h2>
			<form onSubmit={confirmNotification} className="targetCompletionForm">
				<div className="inputRow">
					<label htmlFor="notificationTeam">Team (begeleider) naam</label>
					<select id="notificationTeam" name="notificationTeam" value={notificationTeam} onChange={(event) => setNotificationTeam(event.target.value)}>
						<option value="none">Geen team geselecteerd</option>
						{allTeamNames.map((teamName, i) => (teamNameToIndex(teamName) === gameSettings.orgaTeam ? '' : <option key={i} value={teamNameToIndex(teamName)}>{teamName}</option>))}
						{gameSettings.orgaTeam ? <option value="orgaTeam">Het begeleiderteam: {allTeamNames.find(name => teamNameToIndex(name) === gameSettings.orgaTeam)}</option> : ''}
						<option value="allBut">Alle teams behalve het begeleidersteam</option>
						<option value="all">Alle teams, inclusief het begeleidersteam</option>
					</select>
				</div>
				<div className="inputRow">
					<label htmlFor="notification">Notificatie</label>
					<input type="text" value={notification} onChange={(event) => setNotification(event.target.value)} />
				</div>
				<input type="submit" className="btn submit" onClick={confirmNotification} value="Bevestig" />
			</form>
			<h2>Doelwit gehaald</h2>
			<p>Hiermee kun je handmatig aangeven dat een team een doelwit heeft gehaald. Dit hoort automatisch te gaan, maar bij bugs kan het handig zijn dit formulier te gebruiken.</p>
			<form onSubmit={confirmTargetCompletion} className="targetCompletionForm">
				<div className="inputRow">
					<label htmlFor="targetCompletionTeam">Team (begeleider) naam</label>
					<select id="targetCompletionTeam" name="targetCompletionTeam" value={targetCompletionTeam} onChange={(event) => setTargetCompletionTeam(event.target.value)}>
						<option value="none">Geen team geselecteerd</option>
						{allTeamNames.map((teamName, i) => (teamNameToIndex(teamName) === gameSettings.orgaTeam ? '' : <option key={i} value={teamNameToIndex(teamName)}>{teamName}</option>))}
					</select>
				</div>
				<input type="submit" className="btn submit" onClick={confirmTargetCompletion} value="Bevestig" />
			</form>
		</div>
	)
}

const stateMap = (state) => ({
	gameSettings: state.gameSettings,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)