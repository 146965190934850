const defaultLocation = { // Set to Heino centrum.
	lat: 52.4356,
	lng: 6.2303,
}

const defaultZoom = 14 // This is enough to give a decent overview. Users can zoom in themselves.

const colors = [
	[244, 67, 54],
	[156, 39, 176],
	[0, 188, 212],
	[255, 235, 59],
	[63, 81, 181],
	[33, 150, 24],
	[0, 150, 136],
	[139, 195, 74],
	[255, 193, 7],
	[33, 169, 244],
	[205, 220, 57],
	[103, 58, 183],
	[255, 152, 0],
	[255, 87, 34],
	[76, 175, 80],
	[121, 85, 72],
	[233, 30, 99],
	[158, 158, 158],
	[96, 125, 139],
	[47, 47, 47],
]

const colorToHex = (color) => {
	return '#' + color.map(numToHex).join('')
}

const numToHex = (num) => {
	return ('0' + (Number(num).toString(16))).slice(-2).toUpperCase()
}

export { defaultLocation, defaultZoom, colors, colorToHex }