import React from 'react'
import Wrapper from './Wrapper.js'

export default () => (
	<Wrapper>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
			<g>
				<path fill="none" stroke="currentColor" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M122.5,169.4c0,12.4-10.1,22.5-22.5,22.5s-22.5-10.1-22.5-22.5" />
				<path fill="none" stroke="currentColor" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M114.6,36.6v-15.7c0-7-6-12.8-14.6-12.8s-14.6,5.7-14.6,12.8v15.7" />
				<path fill="none" stroke="currentColor" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.2,86.4c0-25.8,7.7-46.7,26.2-65.5" />
				<path fill="none" stroke="currentColor" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M191.8,86.4c0-25.8-7.7-46.7-26.2-65.5" />
				<path fill="none" stroke="currentColor" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M100,163h77.6c0,0-27.8-14.1-27.8-31.9s0-53.1,0-53.1c0-16.8-22.3-41.4-49.9-41.4s-49.9,24.5-49.9,41.4 c0,0,0,35.4,0,53.1s-27.8,31.9-27.8,31.9z" />
			</g>
		</svg>
	</Wrapper>
)