import './style.scss'

import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

const newIfWithin = 5000 // The number of milliseconds we treat a notification as new.

const Component = ({ notifications, loaded }) => {
	// Check if data has loaded.
	if (!loaded)
		return <div className="notifications">Notificaties worden geladen ... </div>
	if (!notifications)
		return <div className="notifications">Er zijn nog geen notificaties voor je team.</div>

	// Check if there are notifications.
	const notificationArray = Object.keys(notifications).map(key => notifications[key]).sort((a, b) => b.time - a.time)
	if (notificationArray.length === 0)
		return <div className="notifications">Er zijn nog geen notificaties voor jouw team. Die komen vast, zodra het spel begint.</div>

	// Show the notifications.
	const now = new Date()
	return (
		<div className="notifications">
			{notificationArray.map((notification, i) => {
				const dateTime = new Date(notification.time)
				return (
					<div className={classnames("notification", { new: now - dateTime < newIfWithin })} key={notificationArray.length - i} >
						<div className="time">{formatTime(dateTime)}</div>
						<div className="message">{notification.text}</div>
					</div>
				)
			})}
		</div>
	)
}

const formatTime = (date) => {
	return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
}

const stateMap = (state) => ({
	notifications: state.team.private.notifications,
	loaded: state.team.private.loaded,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)