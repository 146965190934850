import { isLoggedIn } from './functions'
import { deepMerge } from '../../support/objects'

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {

    case 'logIn': {
      return deepMerge(state, {
        public: { name: action.teamName },
        teamLead: action.teamLead
      })
    }

    case 'updateTeam': {
      // Ignore updates if we already logged out.
      if (!isLoggedIn(state))
        return state

      // Process the update.
      const result = deepMerge(state, action.team)
      if (action.team.private)
        result.private.targetPositions = action.team.private.targetPositions // Don't do a deep merge on the targetPositions, because that would retain the old positions.
      return result
    }

    case 'logOut': {
      return getDefaultState()
    }

    default: {
      return state
    }
  }
}
export default reducer

const getDefaultState = () => {
  return {
    public: {
      name: undefined,
    },
    private: {},
  }
}
export { getDefaultState }