import './style.scss'

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { usePrevious } from '../../../support/React'
import firebase from '../../../config/firebase'

const teamSeparation = 58 // The number of pixels reserved in the ranking for each team.

const Component = (props) => {
	const [teams, setTeams] = useState(0)
	const [lastChangedTeam, setLastChangedTeam] = useState('')

	useEffect(() => {
		// Start tracking the game ranking information.
		const ref = firebase.database().ref('teams/public')
		const listener = ref.on('value', (snapshot) => {
			const teams = snapshot.val()
			if (teams && props.orgaTeam) // Don't put the orgaTeam in the ranking.
				delete teams[props.orgaTeam]
			setTeams(teams)
		})

		return () => {
			// Close the connection.
			ref.off('value', listener)
		}
	}, [props.orgaTeam])

	// Check if any of the teams have had their score changed.
	const prevTeams = usePrevious(teams)
	useEffect(() => {
		if (!teams)
			return
		const firstRender = !prevTeams || Object.keys(prevTeams).length === 0
		Object.keys(teams).forEach(teamName => {
			const team = teams[teamName]
			const prevTeam = prevTeams[teamName]
			if (!firstRender && (!prevTeam || prevTeam.numCaptures !== team.numCaptures)) {
				setLastChangedTeam(teamName)
				if (navigator.vibrate)
					navigator.vibrate(400) // Briefly vibrate to indicate the change in the ranking.
			}
		})
	}, [teams, prevTeams])

	// Check if there are teams.
	if (teams === 0)
		return <div className="ranking"><p>De ranglijst wordt geladen ...</p></div>
	if (!teams || Object.keys(teams).length === 0)
		return <div className="ranking"><p>Er zijn nog geen teams aangemeld voor het spel.</p></div>

	// Calculate the rank and position of each team.
	const ranking = Object.keys(teams).map(teamName => teams[teamName])
	ranking.sort((a, b) => {
		if (a.numCaptures !== b.numCaptures)
			return b.numCaptures - a.numCaptures
		return (a.lastCapture || 0) - (b.lastCapture || 0)
	})
	ranking.forEach((team, i) => {
		team.position = i
		if (team.numCaptures > 0 || i === 0 || ranking[i - 1].numCaptures > 0)
			team.rank = i + 1
		else
			team.rank = ranking[i - 1].rank
	})

	// Display the ranking.
	return (
		<div className="ranking" style={{ height: ranking.length * teamSeparation }}>
			{Object.keys(teams).map(teamName => {
				// Extract data for this team.
				const team = teams[teamName]
				const ownTeam = props.teamName === team.name
				const changed = teamName === lastChangedTeam

				// Check if we have the orgaTeam.
				if (teamName === props.orgaTeam)
					return null

				return (
					<div key={team.name} className={classnames({ team: true, ownTeam, changed })} style={{ top: teamSeparation * team.position, zIndex: ranking.length - team.position }}>
						<div className="rank">{team.rank}</div>
						<div className="teamDescription">
							<div className="teamName">Team {team.name}</div>
							<div className="teamMembers">{team.members ? team.members.sort().join(', ') : '(Deelnemers onbekend)'}</div>
						</div>
						<div className="score">{team.numCaptures}</div>
					</div>
				)
			})}
		</div>
	)
}

const stateMap = (state) => ({
	teamName: state.team.public.name,
	orgaTeam: state.gameSettings.orgaTeam,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)