import '../../reset.scss'
import '../../index.scss'
import './style.scss'

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getDistance } from 'geolib'

import Header from '../Header'
import Page from '../Page'

import firebase from '../../../config/firebase.js'
import gameSettingsActions from '../../../redux/gameSettings/actions'
import teamActions from '../../../redux/team/actions'
import { teamNameToIndex, teamReachedTarget } from '../../../redux/team/functions'
import { usePrevious } from '../../../support/React'

let blockUpdates = false
let timerIndex = -1
const minWaypointDistance = 40
const minTeamDistance = 20
const minTimeBetweenUpdates = 5 * 1000 // In milliseconds.

const Component = ({ gameSettings, teamData, goToNotificationsPage, logIn, changeGameSettings }) => {

	// Extract further data.
	const gameStatus = parseInt(gameSettings.status)
	const numNotifications = teamData.private.notifications ? Object.keys(teamData.private.notifications).length : -1
	const target = teamData.private.target
	const isTeamLead = teamData && teamData.public.name && teamData.teamLead
	const waypoints = gameSettings.waypoints
	const position = teamData.private.position
	const teamName = teamNameToIndex(teamData.public.name)
	const targetPositions = teamData.private.targetPositions
	const bonusTime = teamData.private.bonusTime
	const active = gameStatus === 1 && teamData.private.active

	// Set up the state.
	const [targetPosition, setTargetPosition] = useState(null)

	// Upon loading, track the game settings.
	useEffect(() => {
		// Start tracking the game settings.
		const ref = firebase.database().ref('gameSettings')
		const listener = ref.on('value', (snapshot) => changeGameSettings({
			loaded: true,
			...snapshot.val(),
		}))
		return () => ref.off('value', listener) // Close the connection.
	}, [changeGameSettings])

	// Upon a change in the gameStatus, buzz the user.
	const previousGameStatus = usePrevious(gameStatus)
	useEffect(() => {
		if (previousGameStatus !== -1 && gameStatus > previousGameStatus) {
			if (navigator.vibrate)
				navigator.vibrate(1200)
		}
	}, [previousGameStatus, gameStatus])

	// Upon a change in the number of notifications, buzz and send the user to the notification page.
	const previousNumNotifications = usePrevious(numNotifications)
	useEffect(() => {
		if (previousNumNotifications !== -1 && numNotifications > previousNumNotifications) {
			goToNotificationsPage()
			if (navigator.vibrate)
				navigator.vibrate([200, 200, 400])
		}
	}, [previousNumNotifications, numNotifications, goToNotificationsPage])

	// Monitor the position of the target. When we get a new one, track its position.
	useEffect(() => {
		// Check if we need to do this.
		if (!isTeamLead || !target || !waypoints || !active)
			return

		if (target.type === 'waypoint') {
			setTargetPosition({ type: 'waypoint', position: waypoints[target.index] })
			return
		} else if (target.type === 'team') {
			// Start listening for position updates.
			const ref = firebase.database().ref(`teams/private/${target.name}/position`)
			const listener = ref.on('value', (snapshot) => {
				setTargetPosition({ type: 'team', name: target.name, position: snapshot.val() })
			})
			return () => ref.off('value', listener) // Close the connection upon a target update.
		} else if (target.type === 'waiting') {
			setTargetPosition(null)
			return
		} else {
			throw new Error('Unknown target type.')
		}
	}, [isTeamLead, target, waypoints, active])

	// Compare the position of this team with its target. 
	useEffect(() => {
		// Check if we need to do something.
		if (!isTeamLead || !position || !targetPosition || blockUpdates || !active || !target)
			return
		if (target.type === 'waypoint' && targetPosition.type !== 'waypoint')
			return
		if (target.type === 'team' && (targetPosition.type !== 'team' || target.name !== targetPosition.name))
			return

		// Check the distance between the team's position and its target. If it's sufficiently small, note that the team reached their target.
		const distance = getDistance(position, targetPosition.position)
		if ((target.type === 'waypoint' && distance <= minWaypointDistance) || (target.type === 'team' && distance <= minTeamDistance)) {
			blockUpdates = true
			teamReachedTarget(teamNameToIndex(teamName)).then(() => {
				setTimeout(() => { blockUpdates = false }, 5000) // Block updates for a couple of seconds, to prevent the script from sending two "team captures" to the database right away.
			})
		}
	}, [teamName, isTeamLead, position, target, targetPosition, active])

	// Make sure that the team gets updates about their target often enough.
	useEffect(() => {
		// Check if we need to do something.
		if (!isTeamLead || !target || !targetPosition || !gameSettings.loaded || !active)
			return

		checkForTargetUpdate({ teamName, target, gameSettings, targetPosition, targetPositions, bonusTime })
	}, [teamName, isTeamLead, target, gameSettings, targetPosition, targetPositions, bonusTime, active])

	// Only for testing.
	useEffect(() => { logIn('iris', true) }, [logIn])

	return (
		<div className="app">
			<Header />
			<Page />
		</div>
	)
}

const stateMap = (state) => ({
	gameSettings: state.gameSettings,
	teamData: state.team,
})
const actionMap = (dispatch) => ({
	logIn: (team, teamLead) => dispatch(teamActions.logIn(team, teamLead)), // Only for testing.
	changeGameSettings: (gameSettings) => dispatch(gameSettingsActions.changeGameSettings(gameSettings)),
	goToNotificationsPage: () => dispatch({ type: 'NOTIFICATIONS' }),
})
export default connect(stateMap, actionMap)(Component)

const checkForTargetUpdate = (data) => {
	const { teamName, target, gameSettings, targetPosition, targetPositions, bonusTime } = data

	// Clear the previous timer, if there was one.
	if (timerIndex !== -1)
		clearTimeout(timerIndex)
	timerIndex = -1

	// If the team doesn't have a target or has a waypoint as a target, do nothing.
	if (!target || target.type !== 'team' || !targetPosition)
		return

	// If the team has a different target than which we know the position of, do nothing.
	if (targetPosition.type !== 'team' || target.name !== targetPosition.name)
		return

	// If the team hasn't had updates yet, give them one right away.
	if (!targetPositions || Object.keys(targetPositions).length === 0) {
		// There is no target data. We should add data now.
		firebase.database().ref(`teams/private/${teamName}/targetPositions`).push(targetPosition.position)
		return
	}

	// There have been updates. Calculate the time at which the next update should be given.
	let timeForNextUpdate
	const latestUpdate = Object.keys(targetPositions).map(key => targetPositions[key]).reduce((latest, current) => (latest === null || latest.time < current.time ? current : latest), null)
	if (target.name === gameSettings.orgaTeam) {
		// The target is the orgaTeam: provide regular updates.
		timeForNextUpdate = new Date(latestUpdate.time + minTimeBetweenUpdates)
	} else {
		// The target is a team. Calculate when the next update should be.
		const searchStartTime = new Date(target.assignedAt)
		const totalSearchTime = parseInt(gameSettings.initialDelay * 1000) / (1 - parseFloat(gameSettings.timeFactor))
		const searchTimeAtLatestUpdate = latestUpdate.time - searchStartTime.getTime() + bonusTime
		const partOfSearchTime = searchTimeAtLatestUpdate / totalSearchTime
		const timeBetweenUpdates = Math.max(gameSettings.initialDelay * 1000 * (1 - partOfSearchTime), minTimeBetweenUpdates)
		timeForNextUpdate = new Date(latestUpdate.time + timeBetweenUpdates)
	}

	// Check if there should be an update. If not, set a timer and do nothing.
	const timeDifference = timeForNextUpdate - new Date()
	if (timeDifference > 0) {
		// The update should be in the future. Set up a timer for it.
		timerIndex = setTimeout(() => checkForTargetUpdate(data), timeDifference)
		return
	}

	// We should add the position of the target. However, we should check if it's a new update. Only if it's new is this sensible.
	if (targetPosition.position.time > latestUpdate.time) {
		firebase.database().ref(`teams/private/${teamName}/targetPositions`).push(targetPosition.position)
	}
}
