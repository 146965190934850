import firebase from '../../config/firebase.js'
import { teamNameToIndex, isLoggedIn, savePosition, savePositionError, addNotification } from './functions'

const types = ['public', 'private']
const teamRef = {}, teamListener = {} // Storage parameters for Firebase references/listeners.
let navigatorListenerIndex

const positionSettings = {
	enableHighAccuracy: true,
	timeout: 10000,
	maximumAge: 0,
}

const actions = {
	logIn: (teamName, teamLead) => (
		(dispatch, getState) => {
			// We shouldn't already be logged in.
			if (isLoggedIn(getState().team))
				throw new Error('Team is already logged in. Cannot log in another team.')

			// Actually log in.
			teamName = teamNameToIndex(teamName)
			dispatch({ type: 'logIn', teamName, teamLead })

			// Start listening for team updates.
			types.forEach(type => {
				teamRef[type] = firebase.database().ref(`teams/${type}/${teamName}`)
				teamListener[type] = teamRef[type].on('value', (snapshot) => dispatch({ type: 'updateTeam', team: { [type]: { ...snapshot.val(), loaded: true } } }))
			})

			// If we are team lead, also listen for location updates.
			if (teamLead) {
				const ref = firebase.database().ref(`teams/private/${teamName}/position`)
				navigatorListenerIndex = navigator.geolocation.watchPosition(({ coords, timestamp }) => savePosition({ coords, time: timestamp, ref }), savePositionError, positionSettings)
			}
		}
	),
	logOut: () => (
		(dispatch, getState) => {
			types.forEach(type => teamRef[type].off('value', teamListener[type])) // Stop listening for team updates.
			navigator.geolocation.clearWatch(navigatorListenerIndex)
			dispatch({ type: 'logOut' })
		}
	),
	signOff: () => (
		(dispatch, getState) => {
			// The team signs off.
			const state = getState()
			const team = state.team
			const teamName = teamNameToIndex(team.public.name)

			// If the team was chasing another team, we must set up all the targeting properly.
			if (team.private.target && (team.private.target.type === 'team' || team.private.target.type === 'waiting')) {
				// The team is chasing another team. We must deal with this properly. First, we need all data.
				firebase.database().ref(`/`).once('value', (snapshot) => {
					// Extract the data from the database.
					const database = snapshot.val()
					if (!database.teams.public[teamName])
						throw new Error(`Could not find the team "${teamName}" in the database.`)
					const team = {
						...database.teams.public[teamName],
						...database.teams.private[teamName],
					}
					const settings = database.gameSettings

					// Walk through the various cases.
					if (settings.stackFront === teamName && settings.stackBack === teamName) {
						// First case: the team is the only team that's alive. We only need to note the new stack front and back.
						addNotification(settings.orgaTeam, `Het team dat op jullie aan het jagen was (team ${team.name}) heeft zich afgemeld voor de speurtocht. Er jaagt op dit moment niemand op je.`)
						firebase.database().ref(`gameSettings`).update({ stackBack: null, stackFront: null })
					} else if (settings.stackBack === teamName) {
						// Second case: the team is at the back of the stack. We only need to note the new stack back.
						addNotification(team.target.name, `Het team dat op jullie aan het jagen was heeft zich afgemeld voor de speurtocht. Er jaagt op dit moment niemand op je.`)
						firebase.database().ref(`gameSettings`).update({ stackBack: team.target.name })
					} else if (settings.stackFront === teamName) {
						// Third case: the team is in the front of the stack. Now we need to note the team targeting him that their target disappeared.
						const chasingTeamName = Object.keys(database.teams.private).find(currTeamName => database.teams.private[currTeamName].target && database.teams.private[currTeamName].target.name === teamName)
						const chasingTeam = {
							...database.teams.public[chasingTeamName],
							...database.teams.private[chasingTeamName],
						}
						const timeSpentSearching = new Date().getTime() - chasingTeam.target.assignedAt
						firebase.database().ref(`teams/private/${chasingTeamName}`).update({
							target: {
								type: 'team',
								name: team.target.name || settings.orgaTeam,
								assignedAt: firebase.database.ServerValue.TIMESTAMP,
							},
							targetPositions: null,
							bonusTime: (timeSpentSearching || 0) + (chasingTeam.bonusTime || 0),
						})
						firebase.database().ref(`gameSettings`).update({ stackFront: chasingTeamName })
						addNotification(chasingTeamName, `Oh ... het team waar jullie op aan het jagen waren (team ${team.name}) heeft zich afgemeld voor de speurtocht. Wat nu? Weet je wat, ik laat jullie even jagen op het geheime begeleidersteam voor een half bonuspunt tot er een nieuw doelwit beschikbaar is.`)
						addNotification(settings.orgaTeam, `Het team dat op jullie aan het jagen was (team ${team.name}) heeft zich afgemeld voor de speurtocht. Nu jaagt team ${chasingTeam.name} (${(chasingTeam.members || []).sort().join(', ')}) op jullie.`)
					} else {
						// Fourth case: the team is in the middle of the stack. We need to notify two teams.
						const chasingTeamName = Object.keys(database.teams.private).find(currTeamName => database.teams.private[currTeamName].target && database.teams.private[currTeamName].target.name === teamName)
						const chasingTeam = {
							...database.teams.public[chasingTeamName],
							...database.teams.private[chasingTeamName],
						}
						const chasedTeamName = team.target.name
						const chasedTeam = {
							...database.teams.public[chasedTeamName],
							...database.teams.private[chasedTeamName],
						}
						const timeSpentSearching = new Date().getTime() - chasingTeam.target.assignedAt
						firebase.database().ref(`teams/private/${chasingTeamName}`).update({
							target: {
								type: 'team',
								name: chasedTeamName,
								assignedAt: firebase.database.ServerValue.TIMESTAMP,
							},
							targetPositions: null,
							bonusTime: (timeSpentSearching || 0) + (chasingTeam.bonusTime || 0),
						})
						addNotification(chasingTeamName, `Oh ... het team waar jullie op aan het jagen was (team ${team.name}) heeft zich afgemeld voor de speurtocht. Gelukkig is er al een nieuw doelwit voor jullie beschikbaar! Jullie taak is nu om team ${chasedTeam.name} (${(chasedTeam.members || []).sort().join(', ')}) op te sporen. Zoals gebruikelijk krijgen jullie weer regelmatig updates over hun locatie.`)
						addNotification(chasedTeamName, `Dat is interessant ... het team dat op jullie aan het jagen was heeft zich afgemeld voor de speurtocht! Een ander team heeft nu de opdracht gekregen jullie op te sporen. Wie zal het zijn?...`)
					}
					
					// Now that the targeting is done, we also sign off the team.
					firebase.database().ref(`teams/private/${teamName}`).update({ active: false, target: null, targetPositions: null })
					addNotification(teamName, 'Je team heeft zich afgemeld voor de speurtocht. Je kunt terugkeren naar het zomerkamp.')
				})
			}
		}
	),
	setMemberList: (members) => (
		(dispatch, getState) => {
			const teamName = teamNameToIndex(getState().team.public.name)
			firebase.database().ref(`teams/public/${teamName}`).update({ members })
		}
	)
}
export default actions