import './style.scss'

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import teamActions from '../../../redux/team/actions'

const Component = ({ team, signOff, setMemberList }) => {
	// Set up state and effects.
	const [members, setMembers] = useState([])
	useEffect(() => { setMembers(team.public.members || []) }, [team.public.members])

	// Set up processing functions.
	// This function asks the user whether he wants to really sign off. If so, the given sign-off function is called.
	const confirmSignOff = () => {
		if (window.confirm('Weet je zeker dat je je team af wilt melden? Dit kan niet ongedaan gemaakt worden!'))
			signOff()
	}
	// This function processes it when a member is changed.
	const processMemberChange = (event, i) => {
		const newMembers = members.slice(0) // Clone array.
		const newName = event.target.value
		if (newName.length === 0)
			newMembers.splice(i, 1)
		else
			newMembers[i] = newName
		setMembers(newMembers)
	}
	// This function processes a member list change.
	const saveMemberList = (event) => {
		event.preventDefault()
		setMemberList(members)
		window.alert('De ledenlijst is aangepast.')
	}

	// Check if we have data.
	if (!team.private)
		return <div className="settings"><p>Laden van de instellingen ...</p></div>

	return (
		<div className="settings">
			<h2>Teamlijst</h2>
			<p>Wie zit er in <strong>Team {team.public.name}</strong>? Vul hier de namen van je teamgenoten in. Je hoeft niet je eigen naam in te voeren. (Je mag dit eventueel later nog wijzigen.)</p>
			<form onSubmit={saveMemberList} className="memberList">
				{members.concat('').map((member, i) => (
					<div key={i} className="inputRow">
						<label htmlFor={`member${i}`}>Lid {i + 1}</label>
						<input type="text" value={member} onChange={(event) => processMemberChange(event, i)} />
					</div>
				))}
				<input type="submit" className="btn submit" onClick={saveMemberList} value="Bevestig team" />
			</form>

			{
				team.private.active ? <>
					<h2>Afmelden</h2>
					<p>Als je team genoeg heeft van de speurtocht, dan kun je je hier afmelden. Het team wat op jouw team jaagte krijgt dan een nieuw doelwit toegewezen. Doe dit alleen als je zeker weet dat het hele team wilt stoppen, want het is niet ongedaan te maken.</p>
					<div className="btn submit" onClick={confirmSignOff}>Ik meld mijn team af</div>
				</> : <>
						<h2>Afmelden</h2>
						<p>Je team heeft zich afgemeld. Je kan niet meer meedoen aan het spel.</p>
					</>
			}
		</div >
	)
}

const stateMap = (state) => ({
	team: state.team,
})
const actionMap = (dispatch) => ({
	setMemberList: (members) => dispatch(teamActions.setMemberList(members)),
	signOff: () => dispatch(teamActions.signOff()),
})
export default connect(stateMap, actionMap)(Component)