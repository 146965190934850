import './style.scss'

import React from 'react'
import { connect } from 'react-redux'

import LogIn from '../../components/LogIn'
import Target from '../../components/Target'
import { isLoggedIn } from '../../../redux/team/functions'

const Component = ({ teamLead, team }) => {
	if (!isLoggedIn(team))
		return <LogIn teamLead={teamLead} />
	return <Target />
}

const stateMap = (state) => ({
	team: state.team,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)