import firebase from 'firebase/app'
import 'firebase/database'
const config = {
  apiKey: "AIzaSyB2swYdeQvH3RvPEb-WN3HH8j4cd5orIAU",
  authDomain: "chase-vvw.firebaseapp.com",
  databaseURL: "https://chase-vvw.firebaseio.com",
  projectId: "chase-vvw",
  storageBucket: "chase-vvw.appspot.com",
  messagingSenderId: "216669849007",
  appId: "1:216669849007:web:0133e972a4336d86"
}
firebase.initializeApp(config)
export default firebase