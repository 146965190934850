const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
		
    case 'changeGameSettings': {
      return {
        ...action.gameSettings,
      }
    }

    default: {
      return state
    }
  }
}
export default reducer

const getDefaultState = () => {
	return {
    loaded: false,
    status: -1, // Game is not yet active.
    initialDelay: 5*60,
    timeFactor: 0.75,
    appointNewGroupToBack: 0, // A counter. When it's 0 we appoint a new group to the front of the stack. They get to chase the superteam. Otherwise they are at the back.
    appointNewGroupToBackLimit: 3, // The limit to the counter. When it's 3, then a third of the teams will be assigned to the front of the stack.
	}
}
export { getDefaultState }