import './style.scss'

import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import loadGoogleMapsAPI from 'load-google-maps-api'

import config from '../../../config/config.json'
import { usePrevious } from '../../../support/React'
import { assignWaypointTarget, teamNameToIndex } from '../../../redux/team/functions'
import { defaultLocation, defaultZoom } from '../../../settings'

let map // This will be a pointer to the Google maps object.
let mapObj // This will be a pointer to the map div.
let lastPosition // This will store the last-known position of the team.
let targetPositionData // This array will contain the positions of the target.
let targetType // This will be either 'waypoint' or 'team'.

// Load the Google maps interface.
let GM
if (window.google && window.google.maps) {
	GM = window.google.maps
} else {
	loadGoogleMapsAPI({ key: config.mapAPIKey })
		.then((result) => { GM = result })
		.catch((error) => { console.error('An error occurred while loading Google Maps', error) })
}

// initializeMap sets up a Google Map object in the designated div. 
const initializeMap = () => {
	// Check if we can actually initialize stuff.
	if (!mapObj)
		return false

	// Set up the map.
	const options = {
		zoom: defaultZoom,
		center: defaultLocation,
		clickableIcons: false, // This is to prevent clickable icons on the map. They disrupt our own events, which is annoying.
		styles: [{ // No places of interest (businesses and such).
			featureType: 'poi',
			stylers: [{ visibility: 'off' }],
		}],
	}
	map = new GM.Map(mapObj, options)

	// Set up event listeners.
	map.addListener('zoom_changed', updateMap) // When the zoom changes, we need to redraw all markers to make them smaller/larger.

	// Note that initialization was successful.
	return true
}

let positionCircle
let targetCircles
const updateMap = () => {
	// Check if we have all data.
	if (!mapObj || !GM || !lastPosition)
		return

	// If we haven't initialized the map yet, do so right away.
	if (!map && !initializeMap())
		return

	// Delete the old position circle and redraw it in the right place.
	const pos = { lat: lastPosition.latitude, lng: lastPosition.longitude }
	if (positionCircle)
		positionCircle.setMap(null)
	if (lastPosition) {
		positionCircle = new GM.Circle({
			strokeColor: '#12489a',
			strokeOpacity: 1,
			strokeWeight: 0,
			fillColor: '#12489a',
			fillOpacity: 1,
			map: map,
			center: pos,
			radius: 0.6 * Math.pow(2, 20 - map.zoom),
		})
	}

	// Draw the target circles.
	if (targetCircles)
		targetCircles.forEach(targetCircle => targetCircle.setMap(null))
	if (targetPositionData) {
		targetCircles = []
		targetPositionData.forEach((targetPosition, i) => {
			const opacity = Math.pow(0.8, targetPositionData.length - i - 1) * (i === targetPositionData.length - 1 ? 1 : 0.5) // Dim older ones, but add an extra factor for all but the newest, to emphasize the newest.
			const pos = { lat: targetPosition.latitude, lng: targetPosition.longitude }
			const color = targetType === 'waypoint' ? '#dd8822' : '#aa0022'
			targetCircles.push(new GM.Circle({
				strokeColor: color,
				strokeOpacity: opacity,
				strokeWeight: 0,
				fillColor: color,
				fillOpacity: opacity,
				map: map,
				center: pos,
				radius: 0.6 * Math.pow(2, 20 - map.zoom),
			}))
		})
	}
}

// The target component.
const Component = (props) => {
	const { status: gameStatus, waypoints, waypointCounter, waypointIncrement, orgaTeam, desiredOrgaTeamPosition } = props.gameSettings
	const { name: teamName } = props.teamData.public
	const { position, target, targetPositions, active, loaded } = props.teamData.private
	const teamDataLoaded = props.teamData.public.loaded && props.teamData.private.loaded
	const numWaypoints = (waypoints ? waypoints.length : 0)
	const mapDiv = useRef(null)
	const isOrgaTeam = teamNameToIndex(teamName) === orgaTeam

	// Store the right mapDiv.
	useEffect(() => {
		mapObj = mapDiv.current
	})

	// Upon dismounting, remove the map reference. We'll get a new map.
	useEffect(() => {
		return () => {
			map = undefined
		}
	}, [])

	// When the position changes, update the map.
	useEffect(() => {
		// Don't do anything if the game is not running.
		if (gameStatus !== 1 && gameStatus !== "1")
			return

		// Store the position and show it on the map.
		lastPosition = position
		updateMap()
	}, [gameStatus, position])

	// When the target positions change, update the map.
	useEffect(() => {
		// Don't do anything if the game is not running.
		if (gameStatus !== 1 && gameStatus !== "1")
			return

		// Check the target type and use it to update target position data.
		if (teamDataLoaded && isOrgaTeam) {
			targetPositionData = [desiredOrgaTeamPosition]
			targetType = 'team' // We'll use a red color.
		} else if (teamDataLoaded && target && target.type === 'waypoint') {
			targetPositionData = [waypoints[target.index]]
			targetType = 'waypoint'
		} else {
			targetPositionData = Object.keys(targetPositions || {}).map(key => targetPositions[key])
			targetType = 'team'
		}
		updateMap()
	}, [gameStatus, teamDataLoaded, target, waypoints, targetPositions, isOrgaTeam, desiredOrgaTeamPosition])

	// Vibrate briefly if there's an update on the target position.
	const numTargetPositions = Object.keys(targetPositions || {}).length
	const numPreviousTargetPositions = Object.keys(usePrevious(targetPositions) || {}).length
	useEffect(() => {
		if (numTargetPositions === numPreviousTargetPositions + 1) {
			if (navigator.vibrate)
				navigator.vibrate(200)
		}
	}, [numTargetPositions, numPreviousTargetPositions])

	// Check if the team has a target. If not, assign it one.
	useEffect(() => {
		if (teamDataLoaded && active && !target && numWaypoints > 0)
			assignWaypointTarget({ teamName, waypointCounter, waypointIncrement, numWaypoints, reason: 'firstTarget' })
	}, [teamDataLoaded, target, teamName, waypointCounter, waypointIncrement, numWaypoints, active])

	// Check if the game is active.
	if (gameStatus === undefined || gameStatus === -1)
		return <div className="target"><p>Bezig met het maken van een verbinding...</p></div>
	if (gameStatus === 0 || gameStatus === '0')
		return <div className="target"><p>Het spel is nog niet begonnen. Wacht tot het spel van start gaat.</p></div>
	if (gameStatus === 2 || gameStatus === '2')
		return <div className="target"><p>Het spel is afgelopen. Keer terug naar het zomerkamp.</p></div>
	if (!loaded)
		return <div className="target"><p>Gegevens worden geladen...</p></div>
	if (!active)
		return <div className="target"><p>Je team heeft zich afgemeld voor de speurtocht. Keer terug naar het zomerkamp.</p></div>

	// Set up an introductory message.
	let message = ''
	if (!props.teamData.private.target)
		message = 'Je hebt nog geen doelwit ... hier wordt voor gezorgd.'
	else if (props.teamData.private.target.type === 'waypoint')
		message = 'Je waypoint is aangegeven in het geel.'
	else if (props.teamData.private.target.type === 'team')
		message = 'De laatst bekende positie van je doelwit is aangegeven in het rood.'
	else if (props.teamData.private.target.type === 'waiting')
		message = 'Je hebt even geen doelwit ... deze wordt voor je gezocht.'

	if (isOrgaTeam)
		message = <p>Dag begeleidersteam! Zie in de onderstaande kaart de positie waar je heen mag lopen.</p>
	else if (props.teamData.teamLead)
		message = <p>Dag begeleider! {message}</p>
	else
		message = <p>Dag deelnemer! {message}</p>

	// Show the target map.
	return (
		<div className="target">
			{message}
			{GM ? (
				<div className="mapContainer">
					<div key="googleMap" id="googleMap" ref={mapDiv} />
				</div>
			) : <div className="mapLoader">Loading map ... </div>}
		</div>
	)
}

const stateMap = (state) => ({
	gameSettings: state.gameSettings,
	teamData: state.team,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)