// deepMerge deep-merges two objects, saving original objects wherever possible but merging them into a new one when required.
const deepMerge = (...params) => {
	// Not two parameters? Or not an object? Use iterative methods.
	if (params.length === 1)
		return params[0]
	if (!params.reduce((allObjects, param) => allObjects && (typeof param === 'object'), true)) // If the parameters are not all objects ...
		return params[params.length - 1] // ... then return the last one, without merging.
	if (params.length > 2)
		return params.reduce((res, param) => deepMerge(res, param), {})

	// Two parameters: merge them.
	const [a, b] = params
	const res = { ...a, ...b }
	intersection(Object.keys(a), Object.keys(b)).forEach(key => {
		res[key] = deepMerge(a[key], b[key])
	})
	return res
}

// intersection returns an array whose elements are the intersection of the elements of two arrays.
const intersection = (a, b) => {
	return a.filter(aKey => b.includes(aKey))
}

export { deepMerge, intersection }