import md5 from 'md5'

import firebase from '../../config/firebase'

// Turns an (upper case) team name into an index.
const teamNameToIndex = (teamName) => {
	return (teamName || '').trim().toLowerCase()
}

// Checks, based on the team state data, whether a team is logged in.
const isLoggedIn = (team) => {
	return team.public.name !== undefined
}

// createNewTeam adds a team with the given name and password to the database. It returns a promise from Firebase about the result.
const createNewTeam = (name, password) => {
	const team = {
		public: {
			name: name.trim(),
			members: [],
			numCaptures: 0,
			numCaptured: 0,
			lastCapture: null,
		},
		private: {
			password: md5(password.trim()),
			position: null,
			active: true,
			target: null,
			targetPositions: {},
			bonusTime: 0,
		},
	}

	return Promise.all([
		firebase.database().ref(`teams/public/${team.public.name.toLowerCase()}`).set(team.public),
		firebase.database().ref(`teams/private/${team.public.name.toLowerCase()}`).set(team.private),
	]).then(() => team)
}

// teamReachedTarget is called when a team has reached their target. It processes this and assigns new targets.
const teamReachedTarget = (teamName) => {
	// We extract the full database and then use that to determine what needs to happen.
	return new Promise((resolve, reject) => {
		firebase.database().ref(`/`).once('value', (snapshot) => {
			const database = snapshot.val()
			if (!database.teams.public[teamName])
				throw new Error(`Could not find the team "${teamName}" in the database.`)
			const team = {
				...database.teams.public[teamName],
				...database.teams.private[teamName],
			}
			const settings = database.gameSettings

			// Check the type of target which the given team had.
			const settingsUpdate = {}
			if (!team.target || !team.target.type) {
				throw new Error(`Team "${teamName} does not have a target yet.`)
			} else if (team.target.type === 'waypoint') {
				// The team was going to a waypoint. Add it to the front or the back of the stack.
				if (!settings.stackFront || !settings.stackBack || parseInt(settings.appointNewGroupToBack) === 0) {
					// Add it to the front.
					settingsUpdate.stackFront = teamName

					// If there is no back of the stack, the new team is the back of the stack.
					if (!settings.stackBack)
						settingsUpdate.stackBack = teamName

					// Make sure this team will chase the orgaTeam.
					firebase.database().ref(`teams/private/${teamName}`).update({
						target: {
							type: 'team',
							name: settings.orgaTeam,
							assignedAt: firebase.database.ServerValue.TIMESTAMP,
						},
						targetPositions: null,
					})
					addNotification(teamName, `Je hebt je waypoint gevonden! Er is alleen nog geen team voor je om op te jagen. Dat betekent dat je tijdelijk het geheime begeleidersteam op kunt proberen te sporen! Weet je hen te vinden voordat er een nieuw doelwit beschikbaar is gekomen, dan krijg je een half bonuspunt plus een geheime beloning. Maar wees snel: als er een doelwit beschikbaar komt voordat je het geheime begeleidersteam gevonden hebt, dan is de kans verloren!`)
					addNotification(settings.orgaTeam, `Het team dat op jullie jaagt is gewisseld. Nu komt team ${team.name} (${(team.members || []).sort().join(', ')}) op jullie af.`)

					// If there was another team chasing the orgaTeam, then give that team a target too.
					if (settings.stackFront) {
						firebase.database().ref(`teams/private/${settings.stackFront}`).update({
							target: {
								type: 'team',
								name: teamName,
								assignedAt: firebase.database.ServerValue.TIMESTAMP,
							},
							targetPositions: null,
						})
						if (database.teams.private[settings.stackFront].target.type === 'waiting') // Als de stack front het begeleidersteam al gepakt heeft.
							addNotification(settings.stackFront, `Eindelijk ... er is een nieuw doelwit! Jullie taak is om team ${team.name} (${(team.members || []).sort().join(', ')}) op te sporen. De huidige locatie van het team is weergegeven in de kaart. Over enkele minuten volgt de volgende update. Pak ze!`)
						else // Als de stack front het begeleidersteam nog niet gepakt heeft.
							addNotification(settings.stackFront, `Helaas, je bent te laat! Het geheime begeleidersteam is vervlogen. Je hebt een nieuw doelwit gekregen. Jullie taak is nu om team ${team.name} (${(team.members || []).sort().join(', ')}) op te sporen. De huidige locatie van het team is weergegeven in de kaart. Over enkele minuten volgt de volgende update. Pak ze!`)
						addNotification(teamName, `Een onbekend team heeft zojuist de taak gekregen jullie op te sporen. Pas goed op!`)
					}
				} else {
					// Add it to the back.
					settingsUpdate.stackBack = teamName

					// Give the team the right target.
					const targetTeam = {
						...database.teams.public[settings.stackBack],
						...database.teams.private[settings.stackBack],
					}
					firebase.database().ref(`teams/private/${teamName}`).update({
						target: {
							type: 'team',
							name: settings.stackBack,
							assignedAt: firebase.database.ServerValue.TIMESTAMP,
						},
						targetPositions: null,
					})
					addNotification(teamName, `Je hebt je waypoint gevonden! Dat betekent dat je nu op een team kunt gaan jagen. Jullie taak is om team ${targetTeam.name} (${(targetTeam.members || []).sort().join(', ')}) te vinden. Ik heb de huidige locatie van het team in de kaart gezet. Elke paar minuten volgt een update. Succes!`)
					addNotification(settings.stackBack, `Een onbekend team heeft zojuist de taak gekregen jullie op te sporen. Pas goed op!`)
				}

				// Increase the appointing counter.
				settingsUpdate.appointNewGroupToBack = (parseInt(settings.appointNewGroupToBack) + 1) % parseInt(settings.appointNewGroupToBackLimit)
			} else if (team.target.type === 'team') {
				// The team has captured another team. Is this the orgaTeam?
				if (team.target.name === settings.orgaTeam) {
					// Give half a bonus point and remove their target.
					firebase.database().ref(`teams/private/${teamName}`).update({
						target: {
							type: 'waiting',
							assignedAt: firebase.database.ServerValue.TIMESTAMP,
						},
						targetPositions: null,
					})
					firebase.database().ref(`teams/public/${teamName}`).update({
						numCaptures: parseFloat(team.numCaptures || 0) + 0.5,
						lastCapture: firebase.database.ServerValue.TIMESTAMP,
					})
					addNotification(teamName, `Gefeliciteerd! Je hebt het geheime begeleidersteam gevonden voor een half bonuspunt en een extra beloning. Ik ga zo snel mogelijk op zoek om een nieuw doelwit voor jullie te vinden.`)
					addNotification(settings.orgaTeam, `Je bent gevonden door team ${team.name} (${(team.members || []).sort().join(', ')}). Geef ze vooral wat lekkers en houd ze bezig (en rustig) tot er een nieuw doelwit voor ze gevonden is.`)

					// Make sure that the next team that finds a waypoint is appointed to the front.
					settingsUpdate.appointNewGroupToBack = 0
				} else {
					// The team captured a regular team. Give them a point and assign them the next team.
					const capturedTeam = {
						...database.teams.public[team.target.name],
						...database.teams.private[team.target.name],
					}
					firebase.database().ref(`teams/private/${teamName}`).update({
						target: {
							type: 'team',
							name: capturedTeam.target.name || settings.orgaTeam,
							assignedAt: firebase.database.ServerValue.TIMESTAMP,
						},
						targetPositions: null,
					})
					firebase.database().ref(`teams/public/${teamName}`).update({
						numCaptures: parseFloat(team.numCaptures || 0) + 1,
						lastCapture: firebase.database.ServerValue.TIMESTAMP,
					})
					firebase.database().ref(`teams/public/${team.target.name}`).update({
						numCaptured: parseFloat(capturedTeam.numCaptured || 0) + 1,
					})

					// For the captured team, assign them a waypoint. This function also automatically adds a notification.
					const timeSpentSearching = new Date().getTime() - capturedTeam.target.assignedAt
					assignWaypointTarget({
						teamName: team.target.name,
						waypointCounter: settings.waypointCounter,
						waypointIncrement: settings.waypointIncrement,
						numWaypoints: settings.waypoints.length,
						reason: 'death',
						bonusTime: timeSpentSearching,
					})

					// Set up notifications for this team. This depends on whether the captured team is the front of the stack.
					if (settings.stackFront === team.target.name) {
						settingsUpdate.stackFront = teamName // Note that the stack front has changed.
						addNotification(teamName, `Je hebt je doelwit gepakt! Gefeliciteerd! Er is alleen even geen nieuw doelwit voor je beschikbaar. Dat betekent dat je tijdelijk op het geheime begeleidersteam kunt jagen! Weet je hen te vinden voordat er een nieuw doelwit beschikbaar is gekomen, dan krijg je een half bonuspunt plus een geheime beloning. Maar wees snel: als er een doelwit beschikbaar komt voordat je het geheime begeleidersteam gevonden hebt, dan is de kans verloren!`)
					} else {
						const targetTeam = {
							...database.teams.public[capturedTeam.target.name],
							...database.teams.private[capturedTeam.target.name],
						}
						addNotification(teamName, `Je hebt je doelwit gepakt! Gefeliciteerd! Jullie nieuwe doelwit is het oude doelwit van het gepakte team: team ${targetTeam.name} (${(targetTeam.members || []).sort().join(', ')}). Jullie krijgen weer regelmatig updates over hun locatie.`)
					}
				}
			} else {
				throw new Error(`Unknown target type "${team.target.type}".`)
			}

			// Store the new settings. Once this is done, we resolve the returned promise.
			firebase.database().ref(`gameSettings`).update(settingsUpdate).then(() => resolve())
		})
	})
}

// assignWaypointTarget gives a team a waypoint as a target.
const assignWaypointTarget = (data) => {
	data.teamName = data.teamName.toLowerCase()

	// Assign a waypoint. Also store the bonus time for the team, if given.
	firebase.database().ref(`teams/private/${data.teamName}`).update({
		target: {
			type: 'waypoint',
			index: data.waypointCounter,
			assignedAt: firebase.database.ServerValue.TIMESTAMP,
		},
		targetPositions: null,
		bonusTime: data.bonusTime || 0,
	})

	// Update the waypoint counter.
	const newWaypointCounter = (parseInt(data.waypointCounter) + parseInt(data.waypointIncrement)) % parseInt(data.numWaypoints)
	firebase.database().ref(`gameSettings/waypointCounter`).set(newWaypointCounter)

	// Add a notification. Which one depends on the reason the team is going to a waypoint.
	if (data.reason === 'firstTarget')
		addNotification(data.teamName, `Om het spel te beginnen, en "levend" te worden, moet je team eerst naar een waypoint. Dit is een semi-willekeurig kruispunt in Heino. Ik heb hem voor je aangegeven op de kaart. Als je daar bent krijg je verdere instructies.`)
	else if (data.reason === 'death')
		addNotification(data.teamName, `Oh nee! Je bent gepakt! Om weer levend te worden, moet je wederom een waypoint opzoeken. Ik heb een waypoint voor jullie geopend. Deze vind je op de kaart.`)
	else
		throw new Error('No valid reason for assigning a waypoint is given.')
}

// addNotification adds a notification to the given team.
const addNotification = (teamName, notification) => {
	firebase.database().ref(`teams/private/${teamName}/notifications`).push({
		text: notification,
		time: firebase.database.ServerValue.TIMESTAMP,
	})
}

// savePosition takes a location update from the navigator, processes it and sends it to the Firebase database.
const savePosition = ({ coords, time, ref }) => {
	const { latitude, longitude, accuracy } = coords
	const position = {
		latitude,
		longitude,
		accuracy,
		time,
	}
	ref.set(position)
}

// savePositionError
const savePositionError = (error) => {
	console.error('Something went wrong with the position updates.')
	console.error(error)
}

export { teamNameToIndex, isLoggedIn, createNewTeam, assignWaypointTarget, teamReachedTarget, addNotification, savePosition, savePositionError }