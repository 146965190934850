import React from 'react'
import Wrapper from './Wrapper.js'

const w = 100
const h = 100
const t = 18
const padding = 6

export default () => (
	<Wrapper>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
			<line stroke="currentColor" strokeWidth={t} strokeLinecap="round" x1={padding + t / 2} y1={padding + t / 2} x2={w - padding - t / 2} y2={h - padding - t / 2} />
			<line stroke="currentColor" strokeWidth={t} strokeLinecap="round" x1={padding + t / 2} y1={h - padding - t / 2} x2={w - padding - t / 2} y2={padding + t / 2} />
		</svg>
	</Wrapper>
)
