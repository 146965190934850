import React from 'react'
import Wrapper from './Wrapper.js'

const w = 100
const h = 100
const t = 8
const m = 4 // Margin
const blockWidths = [89, 71, 57]
const blockHeight = (h - t) / blockWidths.length - 2 * m

export default () => (
	<Wrapper>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
			<line stroke="currentColor" strokeWidth={t} strokeLinecap="round" x1={t / 2} y1={t / 2} x2={t / 2} y2={w - t / 2} />
			{blockWidths.map((blockWidth, i) => <rect key={i} stroke="currentColor" strokeWidth={t} fill="currentColor" strokeLinejoin="round" strokeMiterlimit={t} x={t / 2} y={t + m + i * (blockHeight + 2 * m)} width={blockWidth} height={blockHeight - t} />)}
		</svg>
	</Wrapper>
)
