import './style.scss'

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import OverviewMap from '../../components/OverviewMap'

import firebase from '../../../config/firebase'
import { teamNameToIndex } from '../../../redux/team/functions'
import { colors, colorToHex } from '../../../settings'

const Component = ({ gameSettings }) => {
	const [teams, setTeams] = useState()

	// Upon loading, track all teams.
	useEffect(() => {
		const ref = firebase.database().ref('teams')
		const listener = ref.on('value', (snapshot) => {
			const teamObj = snapshot.val()
			const teams = Object.keys(teamObj.public).map(teamName => ({
				...teamObj.private[teamName],
				...teamObj.public[teamName],
			}))
			setTeams(teams)
		})
		return () => ref.off('value', listener)
	}, [setTeams])

	// Check edge cases.
	if (!teams || !gameSettings.loaded)
		return <div className="overview"><p>Data wordt geladen...</p></div>
	if (teams.length === 0)
		return <div className="overview"><p>Er zijn nog geen teams.</p></div>

	// Extract team arrays.
	teams.forEach((team, i) => team.index = i)
	const activeTeams = teams.filter(team => team.active && teamNameToIndex(team.name) !== gameSettings.orgaTeam)
	const livingTeams = activeTeams.filter(team => team.target && (team.target.type === 'team' || team.target.type === 'waiting'))
	const nonLivingTeams = activeTeams.filter(team => !team.target || team.target.type === 'waypoint')
	const inactiveTeams = teams.filter(team => !team.active && teamNameToIndex(team.name) !== gameSettings.orgaTeam)
	const orgaTeam = teams.find(team => teamNameToIndex(team.name) === gameSettings.orgaTeam)

	// Sort the active teams by their target.
	const livingTeamsSorted = []
	let nextTeamName = gameSettings.stackBack
	if (livingTeams.length > 0) {
		// Browse through the stack.
		while (nextTeamName) {
			const nextTeamNameAsIndex = teamNameToIndex(nextTeamName)
			if (nextTeamNameAsIndex === gameSettings.orgaTeam)
				break
			const nextTeam = livingTeams.find(team => teamNameToIndex(team.name) === nextTeamNameAsIndex)
			if (!nextTeam)
				throw new Error(`Cannot find the next team "${nextTeamName}" in the team stack.`)
			livingTeamsSorted.push(nextTeam)
			nextTeamName = nextTeam.target.name
		}
	}

	return (
		<div className="overview">
			<OverviewMap teams={teams} />
			{livingTeamsSorted.length > 0 ? <>
				<h2>Levende teams</h2>
				<p>Elk team zoekt het volgende team in de rij.</p>
				<ul>
					{livingTeamsSorted.map(displayTeam)}
				</ul>
				<p>Het laatste team jaagt op het begeleidersteam.</p>
			</> : ''}

			{nonLivingTeams.length > 0 ? <>
				<h2>Niet-levende teams</h2>
				<p>Deze teams zijn op weg naar een waypoint om weer levend te worden.</p>
				<ul>
					{nonLivingTeams.map(displayTeam)}
				</ul>
			</> : ''}

			{inactiveTeams.length > 0 ? <>
				<h2>Afgemelde teams</h2>
				<ul>
					{inactiveTeams.map(displayTeam)}
				</ul>
			</> : ''}

			<h2>Begeleidersteam</h2>
			{orgaTeam ? <ul>{displayTeam(orgaTeam)}</ul> : <p>Er is nog geen begeleidersteam ingesteld!</p>}
		</div>
	)
}

const displayTeam = (team, key) => {
	let contents = team.name
	const color = colorToHex(colors[team.index % colors.length])
	if (team.members)
		contents += ` (${team.members.sort().join(', ')})`
	if (team.target && team.target.type === 'waiting')
		contents += ' (Begeleidersteam gevonden. Wachtend op nieuw beschikbaar doelwit.)'
	if (!team.position) {
		contents += ' (Positie onbekend?)'
	} else {
		const dt = Math.floor((new Date().getTime() - team.position.time)/1000)
		if (dt >= 30) {
			const sec = dt % 60
			const min = (dt - sec) / 60
			contents+= ` (Positie verouderd: ${min}:${sec < 10 ? `0${sec}` : sec} minuut oud)`
		}
	}
	return <li key={key}><span className="square" style={{ background: color }} />{contents}</li>
}

const stateMap = (state) => ({
	gameSettings: state.gameSettings,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)