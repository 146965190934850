import './style.scss'

import React from 'react'
import { connect } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'

import pages from '../../pages'

const Component = ({ page, team }) => {
	// Extract the proper page.
	let currentPage = pages[page]
	if (currentPage.allowed && !currentPage.allowed(team))
		currentPage = pages[NOT_FOUND]

	// Show the proper page.
	return (
		<main className="page">
			<currentPage.component key={currentPage.name} />
		</main>
	)
}

const stateMap = (state) => ({
	page: state.location.type,
	team: state.team,
})
const actionMap = (dispatch) => ({})
export default connect(stateMap, actionMap)(Component)